<template>
  <div class="home">
    <site-header/>
    <template v-if="this.$store.state.homeSlides">
      <slide
        v-for="slide in this.$store.state.homeSlides"
        :data="slide"
        :key="slide.id"/>
    </template>
    <div v-else>
      <div class="statusMessage">
        <p>{{ $store.getters.messages('loading') }}..</p>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
  .home
    .statusMessage
      min-height: calc(100vh - 500px);
</style>

<script>
import Header from '@/components/Header.vue'
import HomeSlides from '@/components/HomeSlides.vue'

export default {
  name: 'Home',
  head() {
    return {
      title: this.$store.getters.messages('metaHomeTitle'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.getters.messages('metaHomeDescription')
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$store.getters.messages('metaHomeKeywords')
        }
      ]
    }
  },
  components: {
    'site-header': Header,
    Slide: HomeSlides
  },
  data() {
    return {
      msg: 'Welcome to Planeta Guru'
    }
  },
  mounted() {
    this.showEntelPopup()
    this.$store.dispatch('handleTagManager')
  },
  methods: {
    showEntelPopup() {
      this.$store.dispatch('handleDirectAccess')
    }
  }
}
</script>
